import { generatePath, useNavigate, useParams } from "react-router-dom";
import { getProjectSubRouteTemplate, PROJECT_SUBROUTE, UNSTRUCTURED_DATA_SUBROUTE } from "../app/AppRoutes";
import { ListCdmDataAccessNodes } from "gc-web-proto/galaxycompletepb/apipb/deployment_api_pb";
import { ColumnSortFilterConfig, ListFilterableField } from "../core/data/ListData";
import { SortState } from "../../common/table/DataTable";
import { FilterParams } from "gc-web-proto/galaxycompletepb/commonpb/datafilter_pb";
import { ListCdmStorages } from "gc-web-proto/galaxycompletepb/apipb/cdmapipb/cdm_api_pb";
import { ListCdmMigrations } from "gc-web-proto/galaxycompletepb/apipb/cdmapipb/cdm_api_pb";
import { CdmSynchronizationStatus, CdmSynchronizationStatusFilter } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/cdm_synchronization_status_pb";
import { formatTitleCase } from "../../common/utils/formatter";

export const useNavigateToConnectNewStorageScreen = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const p = generatePath(
        getProjectSubRouteTemplate(PROJECT_SUBROUTE.UNSTRUCTURED_DATA) +
            `/${UNSTRUCTURED_DATA_SUBROUTE.CONNECTED_STORAGE}` +
            `/${UNSTRUCTURED_DATA_SUBROUTE.CONNECT_NEW_STORAGE}`,
        {
            projectId,
        }
    );
    return () => navigate(p);
};

export const useNavigateToConnectedStorageScreen = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const p = generatePath(getProjectSubRouteTemplate(PROJECT_SUBROUTE.UNSTRUCTURED_DATA) + `/${UNSTRUCTURED_DATA_SUBROUTE.CONNECTED_STORAGE}`, {
        projectId,
    });
    return () => navigate(p);
};

export const useNavigateToConnectedStorageDetailsScreen = (storageId: string) => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const p = generatePath(getProjectSubRouteTemplate(PROJECT_SUBROUTE.UNSTRUCTURED_DATA) + `/${UNSTRUCTURED_DATA_SUBROUTE.CONNECTED_STORAGE}/:storageId`, {
        projectId,
        storageId,
    });
    return () => navigate(p);
};

export const useNavigateToUnstructuredDataScreen = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();
    const p = generatePath(getProjectSubRouteTemplate(PROJECT_SUBROUTE.UNSTRUCTURED_DATA), {
        projectId,
    });
    return () => navigate(p);
};

export const useNavigateToCdmMigrationDetailsScreen = () => {
    const navigate = useNavigate();
    const { projectId } = useParams();

    return (migrationId: string) => {
        const p = generatePath(getProjectSubRouteTemplate(PROJECT_SUBROUTE.UNSTRUCTURED_DATA) + `/${UNSTRUCTURED_DATA_SUBROUTE.MIGRATIONS}/:migrationId`, {
            projectId,
            migrationId,
        });
        navigate(p);
    };
};

export const getCdmDataAccessNodeListSortFilterConfig = (): ColumnSortFilterConfig<
    ListCdmDataAccessNodes.Request,
    ListCdmDataAccessNodes.Request.Sort,
    any,
    any
> => {
    return {
        sort: {
            defaultSort: {
                fieldId: ListCdmDataAccessNodes.Request.Sort.Field.NODE_NAME,
                label: "Node Name",
            },
            defaultSortDesc: true,
            sortFields: [
                { fieldId: ListCdmDataAccessNodes.Request.Sort.Field.NODE_NAME, label: "Node Name" },
                { fieldId: ListCdmDataAccessNodes.Request.Sort.Field.LAST_CHECKIN, label: "Last Check-in" },
                { fieldId: ListCdmDataAccessNodes.Request.Sort.Field.LATENCY, label: "Latency" },
                { fieldId: ListCdmDataAccessNodes.Request.Sort.Field.CONNECTED, label: "Connected" },
                { fieldId: ListCdmDataAccessNodes.Request.Sort.Field.REGISTERED_AT, label: "Registered At" },
            ],
            getSortParam: (s: SortState) => {
                return new ListCdmDataAccessNodes.Request.Sort().setField(s.field.fieldId).setDesc(s.descending.value);
            },
        },
        filter: [
            {
                fieldId: ListCdmDataAccessNodes.Request.Filter.FieldCase.NAME,
                label: "Node Name",
                filterType: "string",
                addFilterToRequest: (r: ListCdmDataAccessNodes.Request, f: FilterParams.StringFilter) => {
                    r.addFilterParams(new ListCdmDataAccessNodes.Request.Filter().setName(f));
                },
            },
            {
                fieldId: ListCdmDataAccessNodes.Request.Filter.FieldCase.LABEL,
                label: "Label",
                filterType: "simpleString",
                addFilterToRequest: (r: ListCdmDataAccessNodes.Request, f: FilterParams.SimpleStringFilter) => {
                    r.addFilterParams(new ListCdmDataAccessNodes.Request.Filter().setLabel(f));
                },
            },
            {
                fieldId: ListCdmDataAccessNodes.Request.Filter.FieldCase.CONNECTED,
                label: "Connected",
                filterType: "bool",
                addFilterToRequest: (r: ListCdmDataAccessNodes.Request, f: FilterParams.BoolFilter) => {
                    r.addFilterParams(new ListCdmDataAccessNodes.Request.Filter().setConnected(f));
                },
            },
        ],
    };
};

export const getCdmConnectedStorageListSortFilterConfig = (): ColumnSortFilterConfig<ListCdmStorages.Request, ListCdmStorages.Request.Sort, any, any> => {
    return {
        sort: {
            defaultSort: {
                fieldId: ListCdmStorages.Request.Sort.Field.CREATED_AT,
                label: "Created At",
            },
            defaultSortDesc: true,
            sortFields: [
                { fieldId: ListCdmStorages.Request.Sort.Field.NAME, label: "Name" },
                { fieldId: ListCdmStorages.Request.Sort.Field.VENDOR, label: "Vendor" },
                { fieldId: ListCdmStorages.Request.Sort.Field.CREATED_AT, label: "Created At" },
            ],
            getSortParam: (s: SortState) => {
                return new ListCdmStorages.Request.Sort().setField(s.field.fieldId).setDesc(s.descending.value);
            },
        },
        filter: [
            {
                fieldId: ListCdmStorages.Request.Filter.FieldCase.NAME,
                label: "Name",
                filterType: "string",
                addFilterToRequest: (r: ListCdmStorages.Request, f: FilterParams.StringFilter) => {
                    r.addFilterParams(new ListCdmStorages.Request.Filter().setName(f));
                },
            },
            {
                fieldId: ListCdmStorages.Request.Filter.FieldCase.VENDOR,
                label: "Vendor",
                filterType: "string",
                addFilterToRequest: (r: ListCdmStorages.Request, f: FilterParams.StringFilter) => {
                    r.addFilterParams(new ListCdmStorages.Request.Filter().setVendor(f));
                },
            },
            {
                fieldId: ListCdmStorages.Request.Filter.FieldCase.DESCRIPTION,
                label: "Description",
                filterType: "string",
                addFilterToRequest: (r: ListCdmStorages.Request, f: FilterParams.StringFilter) => {
                    r.addFilterParams(new ListCdmStorages.Request.Filter().setDescription(f));
                },
            },
            {
                fieldId: ListCdmStorages.Request.Filter.FieldCase.HAS_INTEGRATION,
                label: "Has Integration",
                filterType: "bool",
                addFilterToRequest: (r: ListCdmStorages.Request, f: FilterParams.BoolFilter) => {
                    r.addFilterParams(new ListCdmStorages.Request.Filter().setHasIntegration(f));
                },
            },
            {
                fieldId: ListCdmStorages.Request.Filter.FieldCase.LABEL,
                label: "Label",
                filterType: "string",
                addFilterToRequest: (r: ListCdmStorages.Request, f: FilterParams.StringFilter) => {
                    r.addFilterParams(new ListCdmStorages.Request.Filter().setLabel(f));
                },
            },
        ],
    };
};

export const getCdmMigrationListSortFilterConfig = (): ColumnSortFilterConfig<ListCdmMigrations.Request, ListCdmMigrations.Request.Sort, any, any> => {
    return {
        sort: {
            defaultSort: {
                fieldId: ListCdmMigrations.Request.Sort.Field.CREATED_AT,
                label: "Created At",
            },
            defaultSortDesc: true,
            sortFields: [{ fieldId: ListCdmMigrations.Request.Sort.Field.CREATED_AT, label: "Created At" }],
            getSortParam: (s: SortState) => {
                return new ListCdmMigrations.Request.Sort().setField(s.field.fieldId).setDesc(s.descending.value);
            },
        },
        filter: [
            {
                fieldId: ListCdmMigrations.Request.Filter.FieldCase.STATUS,
                label: "Status",
                filterType: "enum",
                enumLabel: "Status",
                enumValuesList: Object.values(CdmSynchronizationStatus.CdmSynchronizationStatus),
                getFormValueDisplayLabel: (v: CdmSynchronizationStatus.CdmSynchronizationStatus) => getCdmMigrationStatusLabel(v),
                createParamFromFormValue: (v: CdmSynchronizationStatus.CdmSynchronizationStatus) =>
                    new CdmSynchronizationStatusFilter().setHas(new CdmSynchronizationStatus().setValue(v)),
                addFilterToRequest: (r: ListCdmMigrations.Request, f: CdmSynchronizationStatusFilter) => {
                    r.addFilterParams(new ListCdmMigrations.Request.Filter().setStatus(f));
                },
            },
        ],
    };
};

const getCdmMigrationStatusLabel = (status: CdmSynchronizationStatus.CdmSynchronizationStatus) => {
    const key = Object.keys(CdmSynchronizationStatus.CdmSynchronizationStatus).find(
        (k) => CdmSynchronizationStatus.CdmSynchronizationStatus[k as keyof typeof CdmSynchronizationStatus.CdmSynchronizationStatus] === status
    );
    return key ? formatTitleCase(key) : "Unknown";
};
