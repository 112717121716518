import { ProjectServiceClient } from "gc-web-proto/galaxycompletepb/apipb/Project_apiServiceClientPb";
import { DeploymentServiceClient } from "gc-web-proto/galaxycompletepb/apipb/Deployment_apiServiceClientPb";

import { IntegrationServiceClient } from "gc-web-proto/galaxycompletepb/apipb/Integration_apiServiceClientPb";

import { PrivateEditionLocalServiceClient } from "gc-web-proto/galaxycompletepb/apipb/Pelocal_apiServiceClientPb";
import { create } from "zustand";
import { getApiEndpoint } from "./grpcCommon";
import { isDevelopment } from "../../common/utils/util";
import { authInterceptor, reqResPrinterInterceptor } from "./grpcapi";
import { PrivateEditionManagementServiceClient } from "gc-web-proto/galaxycompletepb/apipb/Pemanage_apiServiceClientPb";
import { GeneralServiceClient } from "gc-web-proto/galaxycompletepb/apipb/ApiServiceClientPb";
import { AuthenticationServiceClient, LoginServiceClient } from "gc-web-proto/galaxycompletepb/apipb/Auth_apiServiceClientPb";
import { HelpServiceClient } from "gc-web-proto/galaxycompletepb/apipb/Help_apiServiceClientPb";
import { GalaxyMigrateServiceClient } from "gc-web-proto/galaxycompletepb/apipb/gmapipb/Galaxymigrate_apiServiceClientPb";
import { WorkflowServiceClient } from "gc-web-proto/galaxycompletepb/apipb/Workflow_apiServiceClientPb";
import { ChecklistServiceClient } from "gc-web-proto/galaxycompletepb/apipb/Checklist_apiServiceClientPb";
import { LicenseBillingServiceClient } from "gc-web-proto/galaxycompletepb/apipb/License_apiServiceClientPb";
import { SupportServiceClient } from "gc-web-proto/galaxycompletepb/apipb/Support_apiServiceClientPb";
import { ReportServiceClient } from "gc-web-proto/galaxycompletepb/apipb/Report_apiServiceClientPb";
import { ConsumptionPlanningServiceClient } from "gc-web-proto/galaxycompletepb/apipb/Planner_apiServiceClientPb";
import { OperationServiceClient } from "gc-web-proto/galaxycompletepb/apipb/Operation_apiServiceClientPb";
import { CmoServiceClient } from "gc-web-proto/galaxycompletepb/apipb/cmoapipb/Cmo_apiServiceClientPb";
import { CdmServiceClient } from "gc-web-proto/galaxycompletepb/apipb/cdmapipb/Cdm_apiServiceClientPb";

export interface GrpcApiStore {
    authError: Error | null;
    setAuthError: (e: Error | null) => void;

    generalService?: GeneralServiceClient;
    loginService?: LoginServiceClient;
    authService?: AuthenticationServiceClient;
    projectService?: ProjectServiceClient;
    deploymentService?: DeploymentServiceClient;
    helpService?: HelpServiceClient;
    gmService?: GalaxyMigrateServiceClient;
    integrationService?: IntegrationServiceClient;
    workflowService?: WorkflowServiceClient;
    checklistService?: ChecklistServiceClient;
    licenseBillingService?: LicenseBillingServiceClient;
    supportService?: SupportServiceClient;
    privateEditionLocalService?: PrivateEditionLocalServiceClient;
    privateEditionManagementService?: PrivateEditionManagementServiceClient;
    reportService?: ReportServiceClient;
    consumptionPlanningService?: ConsumptionPlanningServiceClient;
    operationService?: OperationServiceClient;
    cmoService?: CmoServiceClient;
    cdmService?: CdmServiceClient;
}

const addr = getApiEndpoint();

const makeAuthInterceptor = (onAuthError: (e: Error) => void) => {
    const handler = (e: Error) => {
        console.debug("authentication error detected. logging out.");
        onAuthError(e);
    };
    return new authInterceptor(handler);
};

export const useGrpcApiStore = create<GrpcApiStore>((set, get) => {
    const onAuthErrorHandler = (e: Error) => {
        set({ authError: e });
    };
    const baseOptions = {
        withCredentials: true,
        unaryInterceptors: [isDevelopment() ? new reqResPrinterInterceptor() : null, makeAuthInterceptor(onAuthErrorHandler)].filter((a) => !!a),
    };
    return {
        generalService: new GeneralServiceClient(addr, null, { ...baseOptions }),
        loginService: new LoginServiceClient(addr, null, { ...baseOptions }),
        authService: new AuthenticationServiceClient(addr, null, { ...baseOptions }),
        projectService: new ProjectServiceClient(addr, null, { ...baseOptions }),
        deploymentService: new DeploymentServiceClient(addr, null, { ...baseOptions }),
        gmService: new GalaxyMigrateServiceClient(addr, null, { ...baseOptions }),
        helpService: new HelpServiceClient(addr, null, { ...baseOptions }),
        integrationService: new IntegrationServiceClient(addr, null, { ...baseOptions }),
        workflowService: new WorkflowServiceClient(addr, null, { ...baseOptions }),
        checklistService: new ChecklistServiceClient(addr, null, { ...baseOptions }),
        licenseBillingService: new LicenseBillingServiceClient(addr, null, { ...baseOptions }),
        supportService: new SupportServiceClient(addr, null, { ...baseOptions }),
        privateEditionLocalService: new PrivateEditionLocalServiceClient(addr, null, { ...baseOptions }),
        privateEditionManagementService: new PrivateEditionManagementServiceClient(addr, null, { ...baseOptions }),
        reportService: new ReportServiceClient(addr, null, { ...baseOptions }),
        consumptionPlanningService: new ConsumptionPlanningServiceClient(addr, null, { ...baseOptions }),
        operationService: new OperationServiceClient(addr, null, { ...baseOptions }),
        cmoService: new CmoServiceClient(addr, null, { ...baseOptions }),
        cdmService: new CdmServiceClient(addr, null, { ...baseOptions }),
        authError: null,
        setAuthError: (e: Error | null) => set({ authError: e }),
    };
});
