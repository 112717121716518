// ======================
// DataAccessNodeListScreen
// ======================

import { ScreenContainer, ScreenTitleBar } from "../../layout/ScreenCommon";
import React, { useState } from "react";
import { PaginationState } from "@tanstack/react-table";
import { useListCdmDataAccessNodes } from "../unstructured_data_hooks";
import { QueryListTable } from "../../../common/table/QueryListTable";
import { CdmDataAccessNodeInfo } from "gc-web-proto/galaxycompletepb/apipb/domainpb/cdm_pb";
import { Box, Card, LinearProgress, Link, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { HostEnvironmentIcon, OnlineStatusIcon } from "../../../common/CommonIcons";
import { getDeploymentConnectionStyle, HostOSIcon, parseCpuCountsFromCpuString } from "../../deployment/DeploymentCommon";
import { getHostEnvDisplayName } from "../../galaxymigrate/GalaxyMigrateCommon";
import { HiOutlineClock } from "react-icons/hi";
import { BlackTooltip } from "../../../common/tooltip/ColorTooltip";
import { formatDurationFromSeconds, formatKnownDataType, KnownDataType } from "../../../common/utils/formatter";
import { sub } from "date-fns";
import { getResourceUsageColor } from "../../galaxymigrate/GalaxyMigrateDeploymentsList";
import { useLinearProgressStyle } from "../../cmcMigration/CmcMigrationCommon";
import { CdmQueryKeys } from "../../../common/QueryKeys";

interface DataAccessNodeListProps {
    projectId: string;
}

export const DataAccessNodeListScreen: React.FC<DataAccessNodeListProps> = (p) => {
    const { projectId } = p;

    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 1,
        pageSize: 30,
    });

    const dataAccessNodesList = useListCdmDataAccessNodes(projectId, pageIndex, pageSize);
    return (
        <ScreenContainer>
            <ScreenTitleBar title={"Data Access Nodes"} />
            <QueryListTable
                grid
                pageCount={dataAccessNodesList.data?.pagerMeta?.totalPages || 0}
                pagination={{ pageIndex, pageSize }}
                setPagination={setPagination}
                listComponent={DataAccessNodeCard}
                data={dataAccessNodesList.data?.itemsList || []}
                refetch={dataAccessNodesList.refetch}
                queryKey={CdmQueryKeys.listCdmDataAccessNodes}
                emptyTableConfig={{
                    title: "No Access Nodes Registered",
                    message: "No access nodes registered for this project. Please register an access node to get started.",
                }}
            />
        </ScreenContainer>
    );
};

// ======================
// DataAccessNodeCard
// ======================

interface DataAccessNodeCardProps {
    data: CdmDataAccessNodeInfo.AsObject;
}

export const DataAccessNodeCard: React.FC<DataAccessNodeCardProps> = (p) => {
    const { data } = p;

    const os = `${data.osClass} - ${data.kernelVersion}`;
    const hasCpuPercentage = !!data.deployment.systemUptime?.seconds;
    const memoryUsagePercentage = (data.memoryUsed / data.memory) * 100;
    const progressStyle = useLinearProgressStyle(true);

    return (
        <Grid
            size={{
                xs: 12,
                md: 6,
                lg: 4,
            }}
        >
            <Card>
                <Box p={2}>
                    <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <OnlineStatusIcon online={data.deployment.connected} size={20} />
                        <Box>
                            <Link variant={"h6"}>{data.deployment.systemName}</Link>
                            <Typography color={"textSecondary"} variant={"subtitle2"}>
                                {"v"}
                                {data.deployment.version}
                            </Typography>
                        </Box>
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} pl={0}>
                        <Typography variant={"body2"}>
                            <HostOSIcon size={12} os={os} /> &nbsp;&nbsp;
                            {os}
                        </Typography>
                    </Stack>
                    <Grid container spacing={1}>
                        <Grid>
                            <Stack direction={"row"} alignItems={"center"} sx={getDeploymentConnectionStyle(data.deployment.connected)}>
                                <HostEnvironmentIcon env={data.deployment.hostEnvironment.value} size={16} /> &nbsp;&nbsp;
                                <Typography variant={"body2"}>{getHostEnvDisplayName(data.deployment.hostEnvironment.value)}</Typography>
                            </Stack>
                        </Grid>
                        <Grid>
                            <Stack direction={"row"} spacing={0.5} alignItems={"center"}>
                                <HiOutlineClock size={16} />
                                <BlackTooltip
                                    title={
                                        <Box>
                                            <Typography fontWeight={600} variant={"subtitle2"}>
                                                {"Last Check-In"}
                                            </Typography>
                                            <Typography variant={"caption"}>{formatKnownDataType(data.deployment.lastCheckin, KnownDataType.DATE)}</Typography>
                                        </Box>
                                    }
                                    arrow
                                >
                                    <Typography
                                        variant={"subtitle2"}
                                        sx={{
                                            textDecoration: "underline",
                                            textDecorationStyle: "dotted",
                                        }}
                                    >
                                        {formatKnownDataType(data.deployment.lastCheckin, KnownDataType.DATE_RELATIVE)}
                                    </Typography>
                                </BlackTooltip>
                            </Stack>
                        </Grid>
                        {!!data.deployment.systemUptime?.seconds && (
                            <Grid>
                                <BlackTooltip
                                    title={
                                        <Box>
                                            <Typography fontWeight={600} variant={"subtitle2"}>
                                                {"Uptime"}
                                            </Typography>
                                            <Typography variant={"caption"}>
                                                {"Last Reboot: "}
                                                {formatKnownDataType(sub(new Date(), { seconds: data.deployment.systemUptime?.seconds }), KnownDataType.DATE)}
                                            </Typography>
                                        </Box>
                                    }
                                    arrow
                                >
                                    <Typography
                                        variant={"subtitle2"}
                                        sx={{
                                            textDecoration: "underline",
                                            textDecorationStyle: "dotted",
                                        }}
                                    >
                                        {"Up "}
                                        {formatDurationFromSeconds(data.deployment.systemUptime?.seconds)}
                                    </Typography>
                                </BlackTooltip>
                            </Grid>
                        )}

                        <Grid>
                            <BlackTooltip
                                title={
                                    <Typography variant={"subtitle2"} fontWeight={"600"}>
                                        {"Latency"}
                                    </Typography>
                                }
                                arrow
                            >
                                <Typography
                                    variant={"subtitle2"}
                                    sx={{
                                        textDecoration: "underline",
                                        textDecorationStyle: "dotted",
                                    }}
                                >
                                    {!!data.deployment.connectionLatency
                                        ? formatKnownDataType(data.deployment.connectionLatency, KnownDataType.DURATION_MILLISECONDS)
                                        : null}
                                </Typography>
                            </BlackTooltip>
                        </Grid>

                        {data.deployment.location && (
                            <Grid>
                                <BlackTooltip
                                    title={
                                        <Box>
                                            <Typography variant={"subtitle2"} fontWeight={600}>
                                                {!!data.deployment.location.city
                                                    ? `${data.deployment.location.city}, ${data.deployment.location.region}`
                                                    : null}
                                            </Typography>
                                            <Typography variant={"caption"} color={"textSecondary"}>
                                                {data.deployment.location.countryName}
                                            </Typography>
                                        </Box>
                                    }
                                    arrow
                                >
                                    <Box pt={0.5}>{data.deployment.location?.countryFlag}</Box>
                                </BlackTooltip>
                            </Grid>
                        )}
                    </Grid>
                    <Stack
                        pt={2}
                        width={"100%"}
                        alignItems={"flexStart"}
                        sx={{
                            opacity: data.deployment.connected ? 1 : 0.3,
                        }}
                    >
                        {hasCpuPercentage ? (
                            <BlackTooltip
                                title={
                                    <Box>
                                        <Typography variant={"caption"} fontWeight={600}>
                                            {data.cpu}
                                        </Typography>
                                        <br />
                                        <Typography variant={"caption"} color={"textSecondary"}>
                                            {`CPU Model`}
                                        </Typography>
                                    </Box>
                                }
                            >
                                <Stack
                                    direction={"row"}
                                    spacing={1}
                                    alignItems={"flex-start"}
                                >
                                    <Box>
                                    <Typography variant={"caption"}>{"CPU "}&nbsp;</Typography>
                                    </Box>
                                    <Stack width={"100%"}>
                                        <Stack direction={"row"} spacing={1} justifyContent={"space-between"}>
                                            <Typography variant={"caption"}>
                                                {parseCpuCountsFromCpuString(data.cpu).cores} {"Cores"}
                                            </Typography>
                                            <Typography variant={"caption"} color={`${getResourceUsageColor(data.cpuUsagePercent)}.main`}>
                                                {formatKnownDataType(data.cpuUsagePercent, KnownDataType.PERCENT)}
                                            </Typography>
                                        </Stack>
                                        <LinearProgress
                                            sx={{
                                                ...progressStyle,
                                                width: "100%",
                                                //minWidth: 200,
                                            }}
                                            variant={"determinate"}
                                            value={data.cpuUsagePercent}
                                            color={getResourceUsageColor(data.cpuUsagePercent)}
                                        />
                                    </Stack>
                                </Stack>
                            </BlackTooltip>
                        ) : null}
                        {data.memoryUsed >= 0 && (
                            <Box pt={1}>
                                <Stack alignItems={"flex-start"} direction={"row"} spacing={1}>
                                    <Typography variant={"caption"}>{"Mem."}</Typography>
                                    <Stack width={"100%"}>
                                        <LinearProgress
                                            sx={{
                                                ...progressStyle,
                                                width: "100%",
                                                //minWidth: 200,
                                            }}
                                            variant={"determinate"}
                                            value={memoryUsagePercentage}
                                            color={getResourceUsageColor(memoryUsagePercentage)}
                                        />
                                        <Stack direction={"row"} justifyContent={"space-between"}>
                                            <Typography variant={"caption"}>
                                                {`${formatKnownDataType(data.memoryUsed, KnownDataType.CAPACITY)}/${formatKnownDataType(
                                                    data.memory,
                                                    KnownDataType.CAPACITY
                                                )}`}
                                            </Typography>
                                            <Typography variant={"caption"} color={`${getResourceUsageColor(memoryUsagePercentage)}.main`}>
                                                {formatKnownDataType(memoryUsagePercentage, KnownDataType.PERCENT)}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </Box>
                        )}
                    </Stack>
                </Box>
            </Card>
        </Grid>
    );
};
