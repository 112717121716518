import {
    mockCdmDataAccessNodeInfo1,
    mockCdmDataAccessNodeInfo2,
    mockCdmDataAccessNodeInfo3,
    mockCdmDataContainerBasicInfo1,
    mockCdmDataContainerBasicInfo2,
    mockCdmDataContainerBasicInfo3,
    mockCdmMigrationDetailedInfo1,
    mockCdmMigrationInfo1,
    mockCdmMigrationsSummary1,
    mockCdmStorageDetails1,
    mockCdmStorageListItem1,
} from "../core/testutils/fixtures/MockCdmService";
import { useGrpcApiStore } from "../grpc/grpcApiStore";
import {
    ListCdmStorages,
    GetCdmStorageDetails,
    AddCdmStorage,
    UpdateCdmStorage,
    DeleteCdmStorage,
    RescanCdmStorage,
    ListCdmStorageDataContainers,
    AddCdmStorageLabel,
    RemoveCdmStorageLabel,
    AddCdmStorageConnection,
    UpdateCdmStorageConnection,
    DeleteCdmStorageConnection,
    RescanCdmStorageConnection,
    CreateCdmMigration,
    ListCdmMigrations,
    GetCdmMigrationDetails,
    TriggerCdmMigrationSync,
    DeleteCdmMigration,
} from "gc-web-proto/galaxycompletepb/apipb/cdmapipb/cdm_api_pb";
import { PagerMeta, PagerParams } from "gc-web-proto/galaxycompletepb/commonpb/datafilter_pb";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { CdmQueryKeys } from "../../common/QueryKeys";
import { ListCdmDataAccessNodes } from "gc-web-proto/galaxycompletepb/apipb/deployment_api_pb";
import { useGlobalTableSortFilterState } from "../../common/table/TableFilterState";
import { useMutationTrackAndAlertError } from "../core/data/useHooksWithErrorHandling";
import { CdmSynchronizationStatus, CdmSynchronizationStatusFilter } from "gc-web-proto/galaxycompletepb/apipb/domainpb/enumpb/cdm_synchronization_status_pb";

export const useListCdmDataAccessNodes = (projectId: string, pageIndex: number, perPage: number) => {
    const apis = useGrpcApiStore();
    const tableState = useGlobalTableSortFilterState((state) => state.tableStateMap.get(CdmQueryKeys.listCdmDataAccessNodes));

    const queryFn = async () => {
        const req = new ListCdmDataAccessNodes.Request().setProjectId(projectId).setPageParams(new PagerParams().setPage(pageIndex).setPerPage(perPage));
        if (tableState?.sortState?.field) {
            req.setSortParam(tableState.sortFilterConfig.sort.getSortParam(tableState.sortState));
        }
        tableState?.filters?.forEach((filter) => {
            tableState.sortFilterConfig.filter.find((f) => f.fieldId === filter.fieldConfig.fieldId)?.addFilterToRequest(req, filter.param);
        });

        const mockResponse = new ListCdmDataAccessNodes.Response()
            .setItemsList([mockCdmDataAccessNodeInfo1, mockCdmDataAccessNodeInfo2, mockCdmDataAccessNodeInfo3])
            .setPagerMeta(new PagerMeta().setTotalPages(1));

        //return mockResponse.toObject();
        const res = await apis.deploymentService.listCdmDataAccessNodes(req, null);
        return res.toObject();
    };

    return useQuery({
        queryKey: [CdmQueryKeys.listCdmDataAccessNodes, pageIndex, perPage],
        queryFn: queryFn,
    });
};
export const useListCdmStorages = (projectId: string, pageIndex: number, perPage: number) => {
    const apis = useGrpcApiStore();
    const tableState = useGlobalTableSortFilterState((state) => state.tableStateMap.get(CdmQueryKeys.listCdmStorages));

    const queryFn = async () => {
        const req = new ListCdmStorages.Request().setProjectId(projectId).setPageParams(new PagerParams().setPage(pageIndex).setPerPage(perPage));
        if (tableState?.sortState?.field) {
            req.setSortParam(tableState.sortFilterConfig.sort.getSortParam(tableState.sortState));
        }
        tableState?.filters?.forEach((filter) => {
            tableState.sortFilterConfig.filter.find((f) => f.fieldId === filter.fieldConfig.fieldId)?.addFilterToRequest(req, filter.param);
        });

        const mockResponse = new ListCdmStorages.Response()
            .setItemsList([mockCdmStorageListItem1, mockCdmStorageListItem1])
            .setPagerMeta(new PagerMeta().setTotalPages(1));

        //return mockResponse.toObject();
        const res = await apis.cdmService.listCdmStorages(req, null);
        return res.toObject();
    };

    return useQuery({
        queryKey: [CdmQueryKeys.listCdmStorages, pageIndex, perPage],
        queryFn: queryFn,
    });
};

// Storage Details
export const useGetCdmStorageDetails = (projectId: string, storageId: string) => {
    const apis = useGrpcApiStore();

    return useQuery({
        queryKey: [CdmQueryKeys.getCdmStorageDetails, projectId, storageId],
        queryFn: async () => {
            const req = new GetCdmStorageDetails.Request().setProjectId(projectId).setStorageId(storageId);

            const mockResponse = new GetCdmStorageDetails.Response().setCdmStorage(mockCdmStorageDetails1);

            //return mockResponse.toObject();
            const res = await apis.cdmService.getCdmStorageDetails(req, null);
            return res.toObject();
        },
    });
};

// Storage Mutations
export const useAddCdmStorage = () => {
    const apis = useGrpcApiStore();

    return useMutationTrackAndAlertError({
        mutationFn: async (params: { projectId: string; request: AddCdmStorage.Request }) => {
            const req = params.request.setProjectId(params.projectId);
            return await apis.cdmService.addCdmStorage(req, null);
        },
    });
};

export const useUpdateCdmStorage = () => {
    const apis = useGrpcApiStore();
    const queryClient = useQueryClient();

    return useMutationTrackAndAlertError(
        {
            mutationFn: async (params: { request: UpdateCdmStorage.Request }) => {
                return await apis.cdmService.updateCdmStorage(params.request, null);
            },
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: [CdmQueryKeys.listCdmStorages] });
                queryClient.refetchQueries({ queryKey: [CdmQueryKeys.listCdmStorages] });
            },
        },
        "Updating Storage..."
    );
};

export const useDeleteCdmStorage = () => {
    const apis = useGrpcApiStore();
    const queryClient = useQueryClient();

    return useMutationTrackAndAlertError({
        mutationFn: async (params: { projectId: string; storageId: string }) => {
            const req = new DeleteCdmStorage.Request().setProjectId(params.projectId).setStorageId(params.storageId);
            return await apis.cdmService.deleteCdmStorage(req, null);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [CdmQueryKeys.listCdmStorages] });
            queryClient.refetchQueries({ queryKey: [CdmQueryKeys.listCdmStorages] });
        },
    });
};

// Storage Data Containers
export const useListCdmStorageDataContainers = (projectId: string, storageId: string, nodeId: string, pageIndex: number, perPage: number) => {
    const apis = useGrpcApiStore();

    return useQuery({
        queryKey: [CdmQueryKeys.listCdmStorageDataContainers, projectId, storageId, nodeId, pageIndex, perPage],
        queryFn: async () => {
            const req = new ListCdmStorageDataContainers.Request()
                .setProjectId(projectId)
                .setStorageId(storageId)
                .setNodeId(nodeId)
                .setPageParams(new PagerParams().setPage(pageIndex).setPerPage(perPage));

            const mockResponse = new ListCdmStorageDataContainers.Response()
                .setItemsList([mockCdmDataContainerBasicInfo1, mockCdmDataContainerBasicInfo2, mockCdmDataContainerBasicInfo3])
                .setPagerMeta(new PagerMeta().setTotalPages(1));

            //return mockResponse.toObject();
            const res = await apis.cdmService.listCdmStorageDataContainers(req, null);
            return res.toObject();
        },
    });
};

// Storage Labels
export const useAddCdmStorageLabel = () => {
    const apis = useGrpcApiStore();

    return useMutationTrackAndAlertError({
        mutationFn: async (params: { projectId: string; storageId: string; label: string; createLabelIfNotExist?: boolean }) => {
            const req = new AddCdmStorageLabel.Request()
                .setProjectId(params.projectId)
                .setStorageId(params.storageId)
                .setLabel(params.label)
                .setCreateLabelIfNotExist(params.createLabelIfNotExist ?? false);
            return await apis.cdmService.addCdmStorageLabel(req, null);
        },
    });
};

export const useRemoveCdmStorageLabel = () => {
    const apis = useGrpcApiStore();

    return useMutationTrackAndAlertError({
        mutationFn: async (params: { projectId: string; storageId: string; label: string }) => {
            const req = new RemoveCdmStorageLabel.Request().setProjectId(params.projectId).setStorageId(params.storageId).setLabel(params.label);
            return await apis.cdmService.removeCdmStorageLabel(req, null);
        },
    });
};

// Storage Connections
export const useAddCdmStorageConnection = () => {
    const apis = useGrpcApiStore();

    return useMutationTrackAndAlertError({
        mutationFn: async (params: { projectId: string; storageId: string; connection: AddCdmStorage.Request.StorageConnection }) => {
            const req = new AddCdmStorageConnection.Request().setProjectId(params.projectId).setStorageId(params.storageId).setConnection(params.connection);
            return await apis.cdmService.addCdmStorageConnection(req, null);
        },
    });
};

export const useUpdateCdmStorageConnection = () => {
    const apis = useGrpcApiStore();

    return useMutationTrackAndAlertError(
        {
            mutationFn: async (params: {
                projectId: string;
                storageId: string;
                connectionId: string;
                updatedConnection: AddCdmStorage.Request.StorageConnection;
            }) => {
                const req = new UpdateCdmStorageConnection.Request()
                    .setProjectId(params.projectId)
                    .setStorageId(params.storageId)
                    .setConnectionId(params.connectionId)
                    .setUpdatedConnection(params.updatedConnection);
                return await apis.cdmService.updateCdmStorageConnection(req, null);
            },
        },
        "Updating Storage Connection..."
    );
};

export const useDeleteCdmStorageConnection = () => {
    const apis = useGrpcApiStore();

    return useMutationTrackAndAlertError({
        mutationFn: async (params: { projectId: string; storageId: string; connectionId: string }) => {
            const req = new DeleteCdmStorageConnection.Request()
                .setProjectId(params.projectId)
                .setStorageId(params.storageId)
                .setConnectionId(params.connectionId);
            return await apis.cdmService.deleteCdmStorageConnection(req, null);
        },
    });
};

// Migrations
export const useCreateCdmMigration = () => {
    const apis = useGrpcApiStore();

    return useMutationTrackAndAlertError({
        mutationFn: async (params: { projectId: string; request: CreateCdmMigration.Request }) => {
            const req = params.request.setProjectId(params.projectId);
            return await apis.cdmService.createCdmMigration(req, null);
        },
    });
};

export const useListCdmMigrations = (
    projectId: string,
    pageIndex: number,
    perPage: number,
    status?: CdmSynchronizationStatus,
    nodeIdList?: string[],
    passesPerMigration?: number
) => {
    const apis = useGrpcApiStore();
    const tableState = useGlobalTableSortFilterState((state) => state.tableStateMap.get(CdmQueryKeys.listCdmMigrations));

    return useQuery({
        queryKey: [CdmQueryKeys.listCdmMigrations, projectId, pageIndex, perPage],
        queryFn: async () => {
            const req = new ListCdmMigrations.Request().setProjectId(projectId).setPageParams(new PagerParams().setPage(pageIndex).setPerPage(perPage));

            if (status) {
                req.addFilterParams(new ListCdmMigrations.Request.Filter().setStatus(new CdmSynchronizationStatusFilter().setHas(new CdmSynchronizationStatus().setValue(status.getValue()))));
            }
            if (nodeIdList) {
                req.setIncludedNodeIdsList(nodeIdList);
            }
            if (passesPerMigration) {
                req.setPassesPerMigration(passesPerMigration);
            }
            if (tableState?.sortState?.field) {
                req.setSortParam(tableState.sortFilterConfig.sort.getSortParam(tableState.sortState));
            }

            tableState?.filters?.forEach((filter) => {
                tableState.sortFilterConfig.filter.find((f) => f.fieldId === filter.fieldConfig.fieldId)?.addFilterToRequest(req, filter.param);
            });

            const mockResponse = new ListCdmMigrations.Response()
                .setItemsList([mockCdmMigrationInfo1])
                .setPagerMeta(new PagerMeta().setTotalPages(1))
                .setSummary(mockCdmMigrationsSummary1);

            return mockResponse.toObject();
        },
    });
};

export const useGetCdmMigrationDetails = (projectId: string, migrationId: string) => {
    const apis = useGrpcApiStore();

    return useQuery({
        queryKey: [CdmQueryKeys.getCdmMigrationDetails, projectId, migrationId],
        queryFn: async () => {
            const req = new GetCdmMigrationDetails.Request().setProjectId(projectId).setMigrationId(migrationId);

            const mockResponse = new GetCdmMigrationDetails.Response().setDetails(mockCdmMigrationDetailedInfo1);

            return mockResponse.toObject();
        },
    });
};

export const useTriggerCdmMigrationSync = () => {
    const apis = useGrpcApiStore();

    return useMutationTrackAndAlertError(
        {
            mutationFn: async (params: { projectId: string; migrationId: string }) => {
                const req = new TriggerCdmMigrationSync.Request().setProjectId(params.projectId).setMigrationId(params.migrationId);
                return await apis.cdmService.triggerCdmMigrationSync(req, null);
            },
        },
        "Triggering Migration Sync..."
    );
};

export const useDeleteCdmMigration = () => {
    const apis = useGrpcApiStore();

    return useMutationTrackAndAlertError({
        mutationFn: async (params: { projectId: string; migrationId: string }) => {
            const req = new DeleteCdmMigration.Request().setProjectId(params.projectId).setMigrationId(params.migrationId);
            return await apis.cdmService.deleteCdmMigration(req, null);
        },
    });
};

export const useRescanCdmStorage = () => {
    const apis = useGrpcApiStore();

    return useMutationTrackAndAlertError(
        {
            mutationFn: async (params: { projectId: string; storageId: string }) => {
                const req = new RescanCdmStorage.Request().setProjectId(params.projectId).setStorageId(params.storageId);
                return await apis.cdmService.rescanCdmStorage(req, null);
            },
        },
        "Rescanning Storage..."
    );
};

export const useRescanCdmStorageConnection = () => {
    const apis = useGrpcApiStore();

    return useMutationTrackAndAlertError(
        {
            mutationFn: async (params: { projectId: string; storageId: string; connectionId: string }) => {
                const req = new RescanCdmStorageConnection.Request()
                    .setProjectId(params.projectId)
                    .setStorageId(params.storageId)
                    .setConnectionId(params.connectionId);
                return await apis.cdmService.rescanCdmStorageConnection(req, null);
            },
        },
        "Rescanning Storage Connection..."
    );
};
