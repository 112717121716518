import { ScreenContainer, ScreenTitleBar } from "../../../layout/ScreenCommon";
import { DynamicStepper } from "../../../../common/stepper/StepperComponents";
import React from "react";
import { StepConfig } from "../../../../common/stepper/StepperHelpers";
import { ConnectNewStorageWizardConfigurationSetupStep } from "./ConnectNewStorageWizardConfigSetupStep";
import { ConnectNewStorageWizardStorageConnectionStep } from "./ConnectNewStorageWizardStorageConnectionsStep";
import { ConnectNewStorageWizardStorageInfoStep } from "./ConnectNewStorageWizardStorageInfoStep";

// ======================
// ConnectNewStorageWizard
// ======================

interface ConnectNewStorageWizardProps {}

export const ConnectNewStorageWizard: React.FC<ConnectNewStorageWizardProps> = (p) => {
    const {} = p;

    const stepConfigs: StepConfig[] = [
        {
            id: "setup",
            label: "Configuration Setup",
            renderer: () => <ConnectNewStorageWizardConfigurationSetupStep />,
        },
        {
            id: "storage",
            label: "Storage Connections",
            renderer: () => <ConnectNewStorageWizardStorageConnectionStep />,
        },
        {
            id: "storage info",
            label: "Storage Information",
            renderer: () => <ConnectNewStorageWizardStorageInfoStep />,
        },
    ];

    return (
        <ScreenContainer>
            <ScreenTitleBar title={"Connect New Storage"} />
            <DynamicStepper
                stepperProps={{
                    //activeStep: 0,
                    stepConfigs: stepConfigs,
                    startingStep: 0,
                    totalSteps: stepConfigs.length,
                }}
                orientation={"horizontal"}
            />
        </ScreenContainer>
    );
};
