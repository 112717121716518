// ======================
// EditConnectionDialog
// ======================

import { Dialog, Stack, FormControl, InputLabel, Select, MenuItem, TextField, Button, Chip } from "@mui/material";
import { AddCdmStorage, UpdateCdmStorage } from "gc-web-proto/galaxycompletepb/apipb/cdmapipb/cdm_api_pb";
import {
    CdmStorageConnection,
    CdmDataAccessNodeInfo,
    CdmStorageListItem,
    CdmDataContainerBasicInfo,
} from "gc-web-proto/galaxycompletepb/apipb/domainpb/cdm_pb";
import { useState } from "react";
import { DialogTopBar } from "../../core/dialog/DialogComponents";
import { DialogState, useShouldDialogFullScreen } from "../../core/dialog/DialogService";
import { useUpdateCdmStorage, useUpdateCdmStorageConnection } from "../unstructured_data_hooks";

interface EditConnectionDialogProps {
    connection: CdmStorageConnection.AsObject;
    dialogState: DialogState;
    dataAccessNodes: Array<CdmDataAccessNodeInfo.AsObject>;
    projectId: string;
}

export const EditConnectionDialog: React.FC<EditConnectionDialogProps> = (p) => {
    const { connection, dialogState, dataAccessNodes, projectId } = p;
    const fullScreen = useShouldDialogFullScreen();
    const updateConnection = useUpdateCdmStorageConnection();
    const [selectedNode, setSelectedNode] = useState<string>(connection?.nodeId || "");
    const [ipAddress, setIpAddress] = useState(connection?.address || "");
    const [port, setPort] = useState(connection?.port || "");
    const [smbFields, setSmbFields] = useState<AddCdmStorage.Request.StorageConnection.SMBConnection.AsObject>({
        username: "",
        password: "",
        domain: "",
    });
    const [s3Fields, setS3Fields] = useState<AddCdmStorage.Request.StorageConnection.S3Connection.AsObject>({
        accessKeyId: "",
        secretAccessKey: "",
        sessionToken: "",
        roleArn: "",
        externalId: "",
    });
    const handleUpdateConnection = async () => {
        const updatedConnection = new AddCdmStorage.Request.StorageConnection().setAddress(ipAddress).setPort(Number(port)).setNodeId(selectedNode);

        if (connection.protocol === CdmStorageConnection.Protocol.PROTOCOL_SMB) {
            updatedConnection.setSmb(
                new AddCdmStorage.Request.StorageConnection.SMBConnection()
                    .setDomain(smbFields.domain)
                    .setPassword(smbFields.password)
                    .setUsername(smbFields.username)
            );
        }

        if (connection.protocol === CdmStorageConnection.Protocol.PROTOCOL_S3) {
            updatedConnection.setS3(
                new AddCdmStorage.Request.StorageConnection.S3Connection()
                    .setAccessKeyId(s3Fields.accessKeyId)
                    .setSecretAccessKey(s3Fields.secretAccessKey)
                    .setSessionToken(s3Fields.sessionToken)
                    .setRoleArn(s3Fields.roleArn)
                    .setExternalId(s3Fields.externalId)
            );
        }

        await updateConnection.mutateAsync({
            projectId: projectId,
            storageId: connection.storageId,
            connectionId: connection.id,
            updatedConnection: updatedConnection,
        });
        dialogState.close();
    };

    const dialogAddButtonDisabled = !selectedNode || !ipAddress || !port;

    return (
        <Dialog fullScreen={fullScreen} maxWidth={"sm"} fullWidth open={dialogState.isOpen} onClose={dialogState.close}>
            <DialogTopBar divider dialogState={dialogState} title={`Edit Connection`} />
            <Stack spacing={2} p={2}>
                <FormControl fullWidth>
                    <InputLabel variant={"filled"} id={"cdm-node-label"}>
                        {"Data Access Node"}
                    </InputLabel>
                    <Select variant={"filled"} labelId={"cdm-node-label"} value={selectedNode} onChange={(e) => setSelectedNode(e.target.value)}>
                        {dataAccessNodes.map((node) => (
                            <MenuItem key={node.deployment.systemId} value={node.deployment.systemId}>
                                {node.deployment.systemName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField variant={"filled"} fullWidth label={"IP Address"} value={ipAddress} onChange={(e) => setIpAddress(e.target.value)} />
                <TextField variant={"filled"} fullWidth label={"Port"} value={port} type={"number"} onChange={(e) => setPort(e.target.value)} />
                {connection.protocol === CdmStorageConnection.Protocol.PROTOCOL_SMB && (
                    <Stack direction={"column"} spacing={2}>
                        <TextField
                            variant={"filled"}
                            fullWidth
                            label={"Username"}
                            value={smbFields.username}
                            onChange={(e) => setSmbFields({ ...smbFields, username: e.target.value })}
                        />
                        <TextField
                            variant={"filled"}
                            fullWidth
                            label={"Password"}
                            value={smbFields.password}
                            onChange={(e) => setSmbFields({ ...smbFields, password: e.target.value })}
                        />
                        <TextField
                            variant={"filled"}
                            fullWidth
                            label={"Domain"}
                            value={smbFields.domain}
                            onChange={(e) => setSmbFields({ ...smbFields, domain: e.target.value })}
                        />
                    </Stack>
                )}
                {connection.protocol === CdmStorageConnection.Protocol.PROTOCOL_S3 && (
                    <Stack direction={"column"} spacing={2}>
                        <TextField
                            variant={"filled"}
                            fullWidth
                            label={"Access Key ID"}
                            value={s3Fields.accessKeyId}
                            onChange={(e) => setS3Fields({ ...s3Fields, accessKeyId: e.target.value })}
                        />
                        <TextField
                            variant={"filled"}
                            fullWidth
                            label={"Secret Access Key"}
                            value={s3Fields.secretAccessKey}
                            onChange={(e) => setS3Fields({ ...s3Fields, secretAccessKey: e.target.value })}
                        />
                        <TextField
                            variant={"filled"}
                            fullWidth
                            label={"Session Token"}
                            value={s3Fields.sessionToken}
                            onChange={(e) => setS3Fields({ ...s3Fields, sessionToken: e.target.value })}
                        />
                        <TextField
                            variant={"filled"}
                            fullWidth
                            label={"Role ARN"}
                            value={s3Fields.roleArn}
                            onChange={(e) => setS3Fields({ ...s3Fields, roleArn: e.target.value })}
                        />
                        <TextField
                            variant={"filled"}
                            fullWidth
                            label={"External ID"}
                            value={s3Fields.externalId}
                            onChange={(e) => setS3Fields({ ...s3Fields, externalId: e.target.value })}
                        />
                    </Stack>
                )}
                <Stack direction={"row"} spacing={2}>
                    <Button variant={"contained"} disabled={dialogAddButtonDisabled} onClick={handleUpdateConnection}>
                        {"Verify And Update"}
                    </Button>
                    <Button variant={"outlined"} color={"neutral"} onClick={dialogState.close}>
                        {"Cancel"}
                    </Button>
                </Stack>
            </Stack>
        </Dialog>
    );
};

// ======================
// EditStorageDialog
// ======================

interface EditStorageDialogProps {
    storage: CdmStorageListItem.AsObject;
    dialogState: DialogState;
    projectId: string;
}

export const EditStorageDialog: React.FC<EditStorageDialogProps> = (p) => {
    const { storage, dialogState, projectId } = p;
    const fullScreen = useShouldDialogFullScreen();
    const updateStorage = useUpdateCdmStorage();
    const [name, setName] = useState(storage.info.name);
    const [description, setDescription] = useState(storage.info.description);
    const [integrationId, setIntegrationId] = useState(null);
    const [vendor, setVendor] = useState(storage.info.vendor);

    const handleUpdateStorage = async () => {
        await updateStorage.mutateAsync({
            request: new UpdateCdmStorage.Request()
                .setProjectId(projectId)
                .setStorageId(storage.info.id)
                .setName(name)
                .setDescription(description)
                .setIntegrationId(integrationId)
                .setVendor(vendor),
        });
        dialogState.close();
    };

    return (
        <Dialog fullScreen={fullScreen} maxWidth={"sm"} fullWidth open={dialogState.isOpen} onClose={dialogState.close}>
            <DialogTopBar divider dialogState={dialogState} title={`Edit Storage`} />
            <Stack spacing={2} p={2}>
                <TextField variant={"filled"} fullWidth label={"Name"} value={name} onChange={(e) => setName(e.target.value)} />
                <TextField variant={"filled"} fullWidth label={"Description"} value={description} onChange={(e) => setDescription(e.target.value)} />
                <TextField variant={"filled"} fullWidth label={"Integration ID"} value={integrationId} onChange={(e) => setIntegrationId(e.target.value)} />
                <TextField variant={"filled"} fullWidth label={"Vendor"} value={vendor} onChange={(e) => setVendor(e.target.value)} />
            </Stack>
            <Stack p={2} direction={"row"} spacing={2}>
                <Button variant={"contained"} onClick={handleUpdateStorage}>
                    {"Update"}
                </Button>
                <Button variant={"outlined"} color={"neutral"} onClick={dialogState.close}>
                    {"Cancel"}
                </Button>
            </Stack>
        </Dialog>
    );
};

// ======================
// StorageConnectionTypeChip
// ======================

export const StorageConnectionTypeChip: React.FC<{ type: CdmStorageConnection.Protocol }> = (p) => {
    const { type } = p;
    const label = getStorageConnectionProtocolLabel(type);
    const color =
        type === CdmStorageConnection.Protocol.PROTOCOL_SMB
            ? "success"
            : type === CdmStorageConnection.Protocol.PROTOCOL_S3
            ? "warning"
            : type === CdmStorageConnection.Protocol.PROTOCOL_NFS
            ? "primary"
            : "default";
    const style = {
        borderRadius: 1,
        color: "white",
        fontWeight: 600,
    };
    return <Chip label={label} color={color} sx={style} />;
};

export const getStorageConnectionProtocolLabel = (protocol: CdmStorageConnection.Protocol) => {
    return protocol === CdmStorageConnection.Protocol.PROTOCOL_SMB
        ? "SMB"
        : protocol === CdmStorageConnection.Protocol.PROTOCOL_S3
        ? "S3"
        : protocol === CdmStorageConnection.Protocol.PROTOCOL_NFS
        ? "NFS"
        : "Unknown";
};

export const CdmDataContainerStatusChip: React.FC<{ status: CdmDataContainerBasicInfo.StorageConnectionStatus }> = (p) => {
    const { status } = p;
    const label =
        status === CdmDataContainerBasicInfo.StorageConnectionStatus.STORAGE_CONNECTION_STATUS_ERROR
            ? "Error"
            : status === CdmDataContainerBasicInfo.StorageConnectionStatus.STORAGE_CONNECTION_STATUS_ONLINE
            ? "Online"
            : "Offline";
    const color = status === CdmDataContainerBasicInfo.StorageConnectionStatus.STORAGE_CONNECTION_STATUS_ONLINE ? "success" : "error";
    return (
        <Chip
            size={"small"}
            variant={status === CdmDataContainerBasicInfo.StorageConnectionStatus.STORAGE_CONNECTION_STATUS_ERROR ? "outlined" : "filled"}
            label={label}
            color={color}
        />
    );
};
