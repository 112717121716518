import { create } from "zustand";
import { ColumnSortFilterConfig } from "../../modules/core/data/ListData";
import { FilterState, SortState } from "./DataTable";
import { getSortOrderLabel, SORT_ORDER } from "./TableSort";
import { CdmQueryKeys, CmoQueryKeys, DeploymentQueryKeys, MigrateOpsQueryKeys, ProjectQueryKeys, ReportQueryKeys } from "../QueryKeys";
import { getProjectListFilterConfig } from "../../modules/project/ProjectCommon";
import { getCmoMigrationSessionsListSortFilterConfig } from "../../modules/cmoMigration/CmoMigrationCommon";
import { getReportListSortFilterConfig } from "../../modules/reports/ReportCommon";
import { getDeploymentListFilterConfig } from "../../modules/galaxymigrate/GmDeploymentCommon";
import { getOperationListFilterConfig } from "../../modules/migrateops/MigrateOpsCommon";
import { getCdmConnectedStorageListSortFilterConfig, getCdmDataAccessNodeListSortFilterConfig, getCdmMigrationListSortFilterConfig } from "../../modules/unstructuredData/UnstructuredDataCommon";

interface TableState {
    sortState: SortState;
    filters: FilterState[];
    sortFilterConfig: ColumnSortFilterConfig<any, any, any, any>;
}

type TableStateMap = Map<string, TableState>;

interface TableSortFilterState {
    tableStateMap: TableStateMap;
    setSortFilterConfig: (tableKey: string, sortFilterConfig: ColumnSortFilterConfig<any, any, any, any>) => void;
    setSort: (tableKey: string, config: SortState) => void;
    toggleSortDirection: (tableKey: string) => void;
    clearSort: (tableKey: string) => void;
    setFilter: (tableKey: string, config: FilterState) => void;
    removeFilter: (tableKey: string, i: number) => void;
    clearFilters: (tableKey: string) => void;
    getIsFilterActive: (tableKey: string) => boolean;
}

const getDefaultSortState = (sortFilterConfig: ColumnSortFilterConfig<any, any, any, any>): SortState => {
    return {
        field: sortFilterConfig.sort.defaultSort,
        descending: {
            label: sortFilterConfig.sort.defaultSortDesc ? getSortOrderLabel(SORT_ORDER.DESCENDING) : getSortOrderLabel(SORT_ORDER.ASCENDING),
            value: sortFilterConfig.sort.defaultSortDesc,
        },
    };
};

const getInitialTableStateMap = (): TableStateMap =>
    new Map()
        .set(ProjectQueryKeys.listMyProjects, {
            sortState: getDefaultSortState(getProjectListFilterConfig()),
            filters: [],
            sortFilterConfig: getProjectListFilterConfig(),
        })
        .set(CmoQueryKeys.listCmoMigrationSessions, {
            sortState: getDefaultSortState(getCmoMigrationSessionsListSortFilterConfig()),
            filters: [],
            sortFilterConfig: getCmoMigrationSessionsListSortFilterConfig(),
        })
        .set(ReportQueryKeys.listProjectReports, {
            sortState: getDefaultSortState(getReportListSortFilterConfig()),
            filters: [],
            sortFilterConfig: getReportListSortFilterConfig(),
        })
        .set(DeploymentQueryKeys.listGmDeployments, {
            sortState: getDefaultSortState(getDeploymentListFilterConfig()),
            filters: [],
            sortFilterConfig: getDeploymentListFilterConfig(),
        })
        .set(MigrateOpsQueryKeys.listProjectOperations, {
            sortState: getDefaultSortState(getOperationListFilterConfig()),
            filters: [],
            sortFilterConfig: getOperationListFilterConfig(),
        })
        .set(CdmQueryKeys.listCdmDataAccessNodes, {
            sortState: getDefaultSortState(getCdmDataAccessNodeListSortFilterConfig()),
            filters: [],
            sortFilterConfig: getCdmDataAccessNodeListSortFilterConfig(),
        })
        .set(CdmQueryKeys.listCdmStorages, {
            sortState: getDefaultSortState(getCdmConnectedStorageListSortFilterConfig()),
            filters: [],
            sortFilterConfig: getCdmConnectedStorageListSortFilterConfig(),
        })
        .set(CdmQueryKeys.listCdmMigrations, {
            sortState: getDefaultSortState(getCdmMigrationListSortFilterConfig()),
            filters: [],
            sortFilterConfig: getCdmMigrationListSortFilterConfig(),
        });

export const useGlobalTableSortFilterState = create<TableSortFilterState>((set, get) => ({
    tableStateMap: getInitialTableStateMap(),
    setSortFilterConfig: (tableKey, sortFilterConfig) => {
        const tableStateMap = new Map(get().tableStateMap);
        const currentTableState = tableStateMap.get(tableKey);
        tableStateMap.set(tableKey, {
            ...currentTableState,
            sortFilterConfig,
        });
        set({ tableStateMap });
    },
    setSort: (tableKey, sortState) => {
        const tableStateMap = new Map(get().tableStateMap);
        const currentTableState = tableStateMap.get(tableKey);
        tableStateMap.set(tableKey, {
            ...currentTableState,
            sortState,
        });
        set({ tableStateMap });
    },
    toggleSortDirection: (tableKey: string) => {
        const tableStateMap = new Map(get().tableStateMap);
        const currentTableState = tableStateMap.get(tableKey);
        const currentSortState = currentTableState.sortState;
        const newSortState = {
            ...currentSortState,
            descending: {
                value: !currentSortState.descending.value,
                label: getSortOrderLabel(currentSortState.descending.value ? SORT_ORDER.DESCENDING : SORT_ORDER.ASCENDING),
            },
        };
        tableStateMap.set(tableKey, {
            ...currentTableState,
            sortState: newSortState,
        });
        set({ tableStateMap });
    },
    clearSort: (tableKey: string) => {
        const tableStateMap = new Map(get().tableStateMap);
        const currentTableState = tableStateMap.get(tableKey);
        tableStateMap.set(tableKey, {
            ...currentTableState,
            sortState: getDefaultSortState(currentTableState.sortFilterConfig),
        });
        set({ tableStateMap });
    },
    setFilter: (tableKey: string, filter) => {
        const tableStateMap = new Map(get().tableStateMap);
        const currentTableState = tableStateMap.get(tableKey);
        const currentFilters = currentTableState.filters;
        tableStateMap.set(tableKey, {
            ...currentTableState,
            filters: [...currentFilters, filter],
        });
        set({ tableStateMap });
    },
    removeFilter: (tableKey, i) => {
        const tableStateMap = new Map(get().tableStateMap);
        const currentTableState = tableStateMap.get(tableKey);
        const currentFilters = currentTableState.filters;
        const newFilters = currentFilters.filter((_, index) => index !== i);
        tableStateMap.set(tableKey, {
            ...currentTableState,
            filters: newFilters,
        });
        set({ tableStateMap });
    },
    clearFilters: (tableKey) => {
        const tableStateMap = new Map(get().tableStateMap);
        const currentTableState = tableStateMap.get(tableKey);
        tableStateMap.set(tableKey, {
            ...currentTableState,
            filters: [],
        });
        set({ tableStateMap });
    },
    getIsFilterActive: (tableKey: string) => {
        const tableStateMap = new Map(get().tableStateMap);
        const currentTableState = tableStateMap.get(tableKey);
        return currentTableState?.filters.length > 0;
    },
}));
