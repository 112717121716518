// ======================
// StepperWrapper
// ======================

import {
    alpha,
    Box,
    BoxProps,
    Button,
    ButtonProps,
    Card,
    Fade,
    LinearProgress,
    linearProgressClasses,
    Slide,
    Step,
    StepLabel,
    Stepper,
    Theme,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { createStepperStore, StepperContext, StepperProps, useStepperContext } from "./StepperState";
import { useEffect, useRef } from "react";

interface StepperWrapperProps {
    stepperProps: StepperProps;
    wrapperBoxProps?: Partial<BoxProps>;
    orientation: "horizontal" | "vertical";
    useTransitions?: boolean;
    hideStepper?: boolean;
}

// ======================
// DynamicStepper
// ======================

export const DynamicStepper: React.FC<StepperWrapperProps> = (p) => {
    const { hideStepper, stepperProps, wrapperBoxProps, orientation, useTransitions } = p;
    let store = useRef(createStepperStore(stepperProps)).current;

    useEffect(() => {
        store.setState(stepperProps);
    }, [stepperProps]);

    return (
        <StepperContext.Provider value={store}>
            <Box {...wrapperBoxProps}>
                {orientation === "horizontal" ? <HorizontalStepper hideStepper={hideStepper} useTransitions={useTransitions} /> : <VerticalStepper />}
            </Box>
        </StepperContext.Provider>
    );
};

// ======================
// HorizontalStepper
// ======================

interface HorizontalStepperProps {
    useTransitions?: boolean;
    hideStepper?: boolean;
}

const HorizontalStepper: React.FC<HorizontalStepperProps> = (p) => {
    const { useTransitions, hideStepper } = p;
    const activeStep = useStepperContext((s) => s.activeStep);
    const stepConfigs = useStepperContext((s) => s.stepConfigs);
    const currentStepConfig = stepConfigs.find((v) => stepConfigs.indexOf(v) === activeStep);
    const totalSteps = stepConfigs.length;

    return (
        <Grid container spacing={3}>
            <Grid size={12}>
                <Card
                    elevation={0}
                    sx={{
                        backgroundColor: (t: Theme) => t.palette.cirrus.medium,
                    }}
                >
                    <Box p={0}>
                        <LinearProgress
                            sx={{
                                height: 7,
                                borderRadius: 5,
                                [`&.${linearProgressClasses.colorPrimary}`]: {
                                    backgroundColor: (t: Theme) => t.palette.cirrus.light,
                                },
                            }}
                            variant={"determinate"}
                            value={((activeStep + 1) / totalSteps) * 100}
                        />
                    </Box>
                </Card>
            </Grid>
            {!hideStepper && (
                <Grid size={12}>
                    <Card
                        elevation={0}
                        sx={{
                            backgroundColor: (t: Theme) => t.palette.cirrus.light,
                        }}
                    >
                        <Box p={2}>
                            <Stepper activeStep={activeStep} orientation={"horizontal"}>
                                {stepConfigs.map((s, i) => {
                                    return (
                                        <Step key={i}>
                                            <StepLabel
                                                StepIconProps={{
                                                    color: "green",
                                                    sx: {
                                                        "& .MuiStepIcon-root": {
                                                            "&.Mui-completed": {
                                                                color: "red",
                                                            },
                                                        },
                                                    },
                                                }}
                                            >
                                                {s.label}
                                                <br />
                                                {s.getDescription ? s.getDescription() : null}
                                            </StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </Box>
                    </Card>
                </Grid>
            )}
            <Grid size={12}>
                {useTransitions
                    ? stepConfigs.map((s, i) => {
                          return s.renderer();
                      })
                    : currentStepConfig.renderer()}
            </Grid>
        </Grid>
    );
};

// ======================
// SlideInStep
// ======================

interface SlideInStepProps {
    direction: "up" | "down";
    transitionIn: boolean;
    children: React.ReactElement;
}

export const SlideAndFadeInStep: React.FC<SlideInStepProps> = (p) => {
    const { transitionIn, direction, children } = p;
    const containerRef = useRef();

    return (
        <Box overflow={"hidden"} width={"100%"} ref={containerRef}>
            <Slide
                easing={{
                    enter: "ease-in-out",
                    exit: "ease-in-out",
                }}
                container={containerRef.current}
                direction={direction}
                in={transitionIn}
                mountOnEnter
                unmountOnExit
            >
                <Box>
                    <Fade timeout={1000} in={transitionIn}>
                        {children}
                    </Fade>
                </Box>
            </Slide>
        </Box>
    );
};

// ======================
// VerticalStepper
// ======================

interface VerticalStepperProps {}

export const VerticalStepper: React.FC<VerticalStepperProps> = (p) => {
    const activeStep = useStepperContext((s) => s.activeStep);
    const stepConfigs = useStepperContext((s) => s.stepConfigs);

    const currentStepConfig = stepConfigs.find((v) => stepConfigs.indexOf(v) === activeStep);

    return (
        <Grid container spacing={3} sx={{ height: "100%" }}>
            <Grid size={3}>
                <Card
                    elevation={0}
                    sx={{
                        backgroundColor: (t: Theme) => t.palette.cirrus.main,
                    }}
                >
                    <Box p={2}>
                        <Stepper
                            activeStep={activeStep}
                            orientation={"vertical"}
                            sx={{
                                backgroundColor: (t: Theme) => t.palette.cirrus.main,
                            }}
                        >
                            {stepConfigs.map((s, i) => {
                                return (
                                    <Step key={i}>
                                        <StepLabel
                                            StepIconProps={{
                                                sx: {
                                                    "&.Mui-completed": {
                                                        color: (t: Theme) => alpha(t.palette.primary.main, 0.5),
                                                    },
                                                },
                                            }}
                                        >
                                            {s.label}
                                            <br />
                                            {s.getDescription ? s.getDescription() : null}
                                        </StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Box>
                </Card>
            </Grid>
            <Grid size={9}>{currentStepConfig.renderer()}</Grid>
        </Grid>
    );
};

// ======================
// StepperNavButtons
// ======================

interface navButtonProps {
    label?: string;
    disabled?: boolean;
    onClick?: Function;
    props?: ButtonProps;
    hide?: boolean;
}
interface StepperNavButtonsProps {
    backButtonProps?: navButtonProps;
    nextButtonProps?: navButtonProps;
}

export const StepperNavButtons: React.FC<StepperNavButtonsProps> = (p) => {
    const { backButtonProps, nextButtonProps } = p;
    const hasPreviousStep = useStepperContext((s) => s.getHasPreviousStep());
    const hasNextStep = useStepperContext((s) => s.getHasNextStep());
    const goBack = useStepperContext((s) => s.goBackOneStep);
    const goForward = useStepperContext((s) => s.goToNextStep);

    const onClickBack = () => {
        if (!!backButtonProps?.onClick) {
            backButtonProps.onClick();
        }
        goBack();
        window.scrollTo(0, 0);
    };

    const onClickNext = () => {
        if (!!nextButtonProps?.onClick) {
            nextButtonProps.onClick();
        }
        goForward();
        window.scrollTo(0, 0);
    };

    return (
        <Grid size={12}>
            <Box display={"flex"} justifyContent={"center"}>
                {!backButtonProps?.hide && (
                    <Box pr={1}>
                        <Button
                            variant="outlined"
                            color="neutral"
                            disabled={backButtonProps?.disabled !== undefined ? backButtonProps?.disabled : !hasPreviousStep}
                            onClick={onClickBack}
                            {...backButtonProps?.props}
                        >
                            {backButtonProps?.label || `Back`}
                        </Button>
                    </Box>
                )}

                {!nextButtonProps?.hide && (
                    <Box>
                        <Button
                            variant={"contained"}
                            color={"primary"}
                            disabled={nextButtonProps?.disabled !== undefined ? nextButtonProps?.disabled : !hasNextStep}
                            onClick={onClickNext}
                            {...nextButtonProps?.props}
                        >
                            {nextButtonProps?.label || `Next`}
                        </Button>
                    </Box>
                )}
            </Box>
        </Grid>
    );
};
