import { Box, Button, Card, Collapse, IconButton, Stack, SvgIcon, Typography, useTheme } from "@mui/material";
import { StepperNavButtons } from "../../../../common/stepper/StepperComponents";
import { useListIntegrationModules, useListProjectIntegrations } from "../../../integrations/integration_hooks";
import { QueryResultWrapper } from "../../../core/data/QueryResultWrapper";
import React, { useEffect, useState } from "react";
import { getIntegrationCategoryDisplayName, getModuleConfigByModule, IntegrationModuleConfig } from "../../../integrations/IntegrationsCommon";
import { SelectableBox } from "../../../../common/card/SelectableCard";
import { alpha } from "@mui/material/styles";
import { RiArrowGoBackFill, RiToolsFill } from "react-icons/ri";
import { BlackTooltip } from "../../../../common/tooltip/ColorTooltip";
import Grid from "@mui/material/Grid2";
import { getUserFullNameFromObject } from "../../../settings/ProjectUsers";
import { formatKnownDataType, KnownDataType } from "../../../../common/utils/formatter";
import { useConnectNewStorageWizardState } from "./ConnectNewStorageWizardState";
import { useGlobalDialogState } from "../../../core/dialog/GlobalDialogState";
import { IntegrationModule } from "gc-web-proto/galaxycompletepb/apipb/domainpb/integration_pb";
import { useListCdmStorages } from "../../unstructured_data_hooks";
import { useCurrentProjectID } from "../../../project/CurrentProjectState";

// ======================
// ConnectNewStorageWizardConfigurationSetupStep
// ======================

interface ConnectNewStorageWizardConfigurationSetupStepProps {}

export const ConnectNewStorageWizardConfigurationSetupStep: React.FC<ConnectNewStorageWizardConfigurationSetupStepProps> = (p) => {
    const {} = p;
    const projectId = useCurrentProjectID();
    const integrationModules = useListIntegrationModules();
    const projectIntegrations = useListProjectIntegrations();
    const connectedStorageList = useListCdmStorages(projectId, 1, 1000);
    const theme = useTheme();

    const wizardState = useConnectNewStorageWizardState();
    const [integrationListExpanded, setIntegrationListExpanded] = useState(wizardState.customSetup === null && wizardState.selectedIntegration === null);
    const [showList, setShowList] = useState(wizardState.customSetup === null && wizardState.selectedIntegration === null);
    const [selectedIntegrationModule, setSelectedIntegrationModule] = useState(
        wizardState.selectedIntegration !== null ? projectIntegrations.data?.itemsList.find((i) => i.id === wizardState.selectedIntegration)?.module : null
    );
    const [selectedIntegrationModuleConfig, setSelectedIntegrationModuleConfig] = useState(
        wizardState.selectedIntegration !== null
            ? getModuleConfigByModule(
                  projectIntegrations.data?.itemsList.find((i) => i.id === wizardState.selectedIntegration)?.module,
                  integrationModules.data?.itemsList
              )
            : null
    );
    const [selectedIntegration, setSelectedIntegration] = useState(wizardState.selectedIntegration);
    const [customSetup, setCustomSetup] = useState(wizardState.customSetup);
    const setSelectedIntegrationInState = useConnectNewStorageWizardState((s) => s.setSelectedIntegration);
    const setSelectedIntegrationModuleInState = useConnectNewStorageWizardState((s) => s.setSelectedIntegrationModule);
    const globalDialogState = useGlobalDialogState();
    const setCustomSetupInState = useConnectNewStorageWizardState((s) => s.setCustomSetup);

    const handleToggleCustomSetup = (customSetup: boolean) => {
        setCustomSetupInState(customSetup);
        setCustomSetup(customSetup);
    };

    const handleChangeSelectedIntegration = (id: number) => {
        setSelectedIntegrationInState(id);
        setSelectedIntegration(id);
    };

    const handleSelectIntegrationModule = (module: IntegrationModule, config: IntegrationModuleConfig) => {
        setIntegrationListExpanded(false);
        setSelectedIntegrationModuleInState(module);
        setSelectedIntegrationModule(module);
        setSelectedIntegrationModuleConfig(config);
        wizardState.setVendor(config.moduleDefinition?.name);
        wizardState.setStorageName(
            `${config.moduleDefinition?.name.split(" ")[0].toLowerCase()}-storage-${
                connectedStorageList.data?.itemsList.filter((s) => s.info.vendor === config.moduleDefinition?.name).length + 1
            }`
        );
    };

    const handleDeselectIntegrationModule = () => {
        setShowList(true);
        setTimeout(() => setIntegrationListExpanded(true), 50);
        setSelectedIntegrationModuleConfig(null);
        setSelectedIntegrationModule(null);
        handleChangeSelectedIntegration(null);
        setSelectedIntegrationInState(null);
        wizardState.setVendor("");
        wizardState.setStorageName("");
    };

    useEffect(() => {
        if (!integrationListExpanded) {
            setTimeout(() => setShowList(integrationListExpanded), 200);
        }
    }, [integrationListExpanded]);

    return (
        <Stack direction={"column"} spacing={2}>
            <Typography variant={"h5"}>{"Select A Configuration Setup Method"}</Typography>
            <QueryResultWrapper queryResult={integrationModules}>
                {showList && (
                    <>
                        {integrationModules.data?.itemsList
                            .sort((a, b) => {
                                return a.name.localeCompare(b.name);
                            })
                            .map((i) => {
                                const config = getModuleConfigByModule(i.module, integrationModules.data?.itemsList);
                                const disabled = !projectIntegrations.data?.itemsList.find((projInt) => projInt.module === i.module);
                                return (
                                    <Collapse in={integrationListExpanded}>
                                        <Box key={i.module}>
                                            <SelectableBox
                                                onSelect={() => {
                                                    handleSelectIntegrationModule(i.module, config);
                                                }}
                                                disabled={disabled}
                                                selected={false}
                                                cardProps={{
                                                    sx: {
                                                        backgroundColor: theme.palette.cirrus.light,
                                                        height: "auto",
                                                        width: "100%",
                                                        position: "relative",
                                                    },
                                                }}
                                            >
                                                <Box p={2}>
                                                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                                        <config.icon size={"small"} />
                                                        <Box>
                                                            <Typography fontWeight={600}>{config.moduleDefinition?.name}</Typography>
                                                            <Typography color={"textSecondary"} variant={"body2"}>
                                                                {getIntegrationCategoryDisplayName(config.category)} / {config.moduleDefinition?.description}
                                                            </Typography>
                                                        </Box>
                                                    </Stack>
                                                </Box>
                                                <Box
                                                    sx={{
                                                        width: `100%`,
                                                        height: `100%`,
                                                        position: "absolute",
                                                        display: disabled ? "block" : "none",
                                                        top: 0,
                                                        left: 0,
                                                        backgroundColor: alpha(theme.palette.cirrus.main, 0.7),
                                                        zIndex: 1000,
                                                        cursor: "not-allowed",
                                                        borderRadius: 2,
                                                    }}
                                                />
                                            </SelectableBox>
                                        </Box>
                                    </Collapse>
                                );
                            })}
                        <Box>
                            <SelectableBox
                                selected={customSetup}
                                onSelect={async () => {
                                    const confirmed = await globalDialogState.addConfirmDialog({
                                        title: "By selecting Custom Setup, you will have to manually configure storage details",
                                        message: `When you select an integration, storage details like names, properties, 
                                                   and configurations are automatically pre-filled, saving time and reducing the chance of error. 
                                                   Opting for a custom setup will require manual configuration in the following steps.`,
                                    });
                                    if (confirmed) {
                                        setIntegrationListExpanded(false);
                                        handleToggleCustomSetup(true);
                                    }
                                }}
                                cardProps={{
                                    sx: {
                                        backgroundColor: theme.palette.cirrus.light,
                                        height: "auto",
                                        width: "100%",
                                        position: "relative",
                                    },
                                }}
                            >
                                <Box p={2}>
                                    <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                        <RiToolsFill size={30} />
                                        <Box>
                                            <Typography fontWeight={600}>{"Custom Setup"}</Typography>
                                            <Typography color={"textSecondary"} variant={"body2"}></Typography>
                                        </Box>
                                    </Stack>
                                </Box>
                            </SelectableBox>
                        </Box>
                    </>
                )}
            </QueryResultWrapper>
            {customSetup && (
                <Card>
                    <Box p={2}>
                        <Stack direction={"row"} spacing={2} alignItems={"center"}>
                            <BlackTooltip title={"Change Setup Method"} arrow>
                                <IconButton
                                    sx={{ borderRadius: 3 }}
                                    onClick={() => {
                                        setShowList(true);
                                        setTimeout(() => setIntegrationListExpanded(true), 50);
                                        handleToggleCustomSetup(false);
                                    }}
                                >
                                    <SvgIcon>
                                        <RiArrowGoBackFill />
                                    </SvgIcon>
                                </IconButton>
                            </BlackTooltip>
                            <RiToolsFill size={30} />
                            <Box>
                                <Typography fontWeight={600}>{"Custom Setup"}</Typography>
                                <Typography color={"textSecondary"} variant={"body2"}></Typography>
                            </Box>
                        </Stack>
                    </Box>
                </Card>
            )}
            {selectedIntegrationModule && (
                <QueryResultWrapper queryResult={projectIntegrations}>
                    <Card>
                        <Box p={2}>
                            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                                <BlackTooltip title={"Change Setup Method"} arrow>
                                    <IconButton sx={{ borderRadius: 3 }} onClick={handleDeselectIntegrationModule}>
                                        <SvgIcon>
                                            <RiArrowGoBackFill />
                                        </SvgIcon>
                                    </IconButton>
                                </BlackTooltip>

                                <selectedIntegrationModuleConfig.icon size={"small"} />
                                <Box>
                                    <Typography fontWeight={600}>{selectedIntegrationModuleConfig.moduleDefinition?.name}</Typography>
                                    <Typography color={"textSecondary"} variant={"body2"}>
                                        {getIntegrationCategoryDisplayName(selectedIntegrationModuleConfig.category)} /{" "}
                                        {selectedIntegrationModuleConfig.moduleDefinition?.description}
                                    </Typography>
                                </Box>
                            </Stack>
                        </Box>
                    </Card>
                    <Grid container spacing={2}>
                        {projectIntegrations.data?.itemsList
                            .filter((int) => int.module === selectedIntegrationModule)
                            .map((i) => {
                                return (
                                    <Grid size={[12, 6, 6, 3]}>
                                        <SelectableBox
                                            onSelect={() => {
                                                handleChangeSelectedIntegration(i.id);
                                            }}
                                            selected={selectedIntegration === i.id}
                                            key={i.module}
                                            cardProps={{
                                                sx: {
                                                    backgroundColor: theme.palette.cirrus.light,
                                                    height: "100%",
                                                },
                                            }}
                                        >
                                            <Stack p={2} spacing={2} direction={"column"} justifyContent={"center"} textAlign={`center`}>
                                                <Typography color={"textSecondary"}>{`id: #${i.id}`}</Typography>
                                                <Box display={"flex"} justifyContent={"center"}>
                                                    <Box>
                                                        <selectedIntegrationModuleConfig.icon />
                                                        <Typography variant={"h5"} fontWeight={600}>
                                                            {i.friendlyName}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Box textAlign={"left"}>
                                                    <Typography variant={"body2"} color={"textSecondary"}>
                                                        {i.description ? `Description: ${i.description}` : "Description: N/A"}
                                                    </Typography>
                                                    <Typography variant={"body2"} color={"textSecondary"}>
                                                        {`Enabled at: ${formatKnownDataType(i.enabledAt, KnownDataType.DATE)}`}
                                                    </Typography>
                                                    <Typography variant={"body2"} color={"textSecondary"}>
                                                        {`Enabled by: ${getUserFullNameFromObject(i.enabledBy)}`}
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                        </SelectableBox>
                                    </Grid>
                                );
                            })}
                    </Grid>
                </QueryResultWrapper>
            )}

            <StepperNavButtons
                nextButtonProps={{
                    disabled: !customSetup && !selectedIntegration,
                }}
            />
        </Stack>
    );
};
